import React, { FC, ReactNode } from 'react';

import Template from '~/components/Template';
import requireUser, { UserProps } from '~/utils/requireUser';

interface TemplateUserProps {
  secondary?: ReactNode;
  title?: string;
}

const TemplateUser: FC<TemplateUserProps & UserProps> = ({
  children,
  secondary,
  title,
}) => (
  <Template kind="user" showNavigation>
    <div className="mx-auto mt-6">
      {title && <h1 className="mb-6 text-xl font-bold">{title}</h1>}
      {undefined !== secondary && (
        <>
          {secondary}
          <hr className="my-6" />
        </>
      )}

      <div> {children}</div>
    </div>
  </Template>
);
export default requireUser(TemplateUser);
